<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card>
      <v-card-title>
        <span class="headline" v-if="selectedWeapon == undefined"
          >Add Weapon</span
        >
        <span class="headline" v-else>Number of kills</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row v-if="this.selectedWeapon == undefined">
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              v-for="weapon in availableWeapons"
              :key="weapon.name"
            >
              <v-card @click="selectWeapon(weapon)">
                <v-card-title>{{ weapon.name }}</v-card-title>
                <v-card-text class="text-center">
                  <img
                    :src="require(`@/assets/images/weapons/${weapon.icon}`)"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" v-else>
            <v-col
              cols="6"
              sm="3"
              md="4"
              lg="2"
              class="d-flex justify-space-around mt-5"
              v-for="index in 5"
              :key="index"
            >
              <v-btn x-large @click="add(index)">{{ index }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import allWeapons from "../components/Weapons";

export default {
  name: "AddPlayer",
  props: ["weapons"],
  components: {},
  data() {
    return {
      dialog: false,
      selectedWeapon: undefined,
      allWeapons: allWeapons,
    };
  },
  computed: {
    availableWeapons() {
      return this.allWeapons.filter(
        (item) => this.alreadyUsedWeapons.indexOf(item.name) == -1
      );
    },
    alreadyUsedWeapons() {
      return this.weapons.map((item) => item.name);
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.selectedWeapon = undefined;
    },
    close() {
      this.dialog = false;
      this.selectedWeapon = undefined;
    },
    selectWeapon(weapon) {
      this.selectedWeapon = weapon;
    },
    add(kills) {
      this.$emit("addedWeapon", {
        name: this.selectedWeapon.name,
        kills: kills,
      });
      this.dialog = false;
    },
  },
};
</script>
