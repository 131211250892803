<template>
  <v-container class="mt-5">
    <v-row>
      <v-col cols="12">
        <h1 class="display-1 mb-4 text-center">Smurf War Games</h1>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="center" justify="space-between">
        <v-btn
          depressed
          color="primary"
          small
          @click="$refs.addPlayer.open()"
          class="mr-5 mt-5 ml-5"
          ><v-icon>mdi-plus</v-icon> Add Player
        </v-btn>
        <v-btn
          depressed
          color="primary"
          small
          @click="$refs.addWeapon.open()"
          class="mr-5 mt-5"
          ><v-icon>mdi-plus</v-icon> Add Weapon
        </v-btn>
        <v-btn
          depressed
          small
          @click="$refs.usePreset.open()"
          class="mr-5 mt-5"
        >
          <v-icon>mdi-repeat</v-icon> Use Weapon preset
        </v-btn>
        <v-btn
          depressed
          color="error"
          small
          :disabled="!game || game.kills.length == 0"
          @click="$refs.resetKills.open()"
          class="mt-5"
        >
          <v-icon>mdi-delete</v-icon> Reset Kills
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="game">
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead></thead>
            <tbody>
              <tr>
                <td class="text-left"></td>
                <td
                  v-for="(player, index) in game.players"
                  :key="index"
                  class="text-h5 text-uppercase"
                >
                  {{ player.name }}
                </td>
              </tr>
              <tr v-for="(weapon, index) in game.weapons" :key="index">
                <td>
                  <img
                    style="max-height: 2em"
                    :src="getWeaponImageUrl(weapon.name)"
                  />
                </td>
                <td v-for="(player, index) in game.players" :key="index">
                  <template v-for="index in weapon.kills">
                    <Kill
                      :key="index"
                      :active="numberOfKillsFor(player, weapon) >= index"
                      :weapon="weapon"
                      :player="player"
                      v-on:addKill="addKill"
                      v-on:removeKill="removeKill"
                    />
                  </template>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <AddPlayer v-on:addedPlayer="addedPlayer" ref="addPlayer" />
      <AddWeapon
        v-on:addedWeapon="addedWeapon"
        ref="addWeapon"
        v-bind:weapons="game.weapons"
      />
      <ResetKills ref="resetKills" v-on:resetKills="resetKills" />
      <UsePreset ref="usePreset" v-on:usePreset="resetWeapons" />
    </v-row>
  </v-container>
</template>

<script>
import firebase from "../Firebase";
const games = firebase.firestore().collection("games");
import allWeapons from "../components/Weapons";
import AddPlayer from "../components/AddPlayer";
import AddWeapon from "../components/AddWeapon";
import Kill from "../components/Kill";
import ResetKills from "../components/ResetKills";
import UsePreset from "../components/UsePreset";

export default {
  name: "Game",
  components: { AddPlayer, AddWeapon, Kill, ResetKills, UsePreset },
  data() {
    return {
      gameId: this.$route.params.gameId,
      ref: firebase.firestore(),
      game: undefined,
      allWeapons: allWeapons,
    };
  },
  methods: {
    updateGame() {
      this.ref
        .collection("games/")
        .doc(this.gameId)
        .set(this.game);
    },
    addedPlayer(playerName) {
      this.game.players.push({
        name: playerName,
        id: Math.random()
          .toString(36)
          .substr(2, 6),
      });
      this.updateGame();
    },
    addedWeapon(weapon) {
      this.game.weapons.push(weapon);
      this.updateGame();
    },
    getWeaponImageUrl(name) {
      const item = this.allWeapons.filter((w) => w.name == name);
      if (item.length > 0) {
        return require(`@/assets/images/weapons/${item[0].icon}`);
      }
    },
    killsFor(player, weapon) {
      return this.game.kills.filter(
        (item) => item.playerId == player.id && item.weaponName == weapon.name
      );
    },
    numberOfKillsFor(player, weapon) {
      return this.killsFor(player, weapon).length;
    },
    addKill(kill) {
      this.game.kills.push(kill);
      this.updateGame();
    },
    removeKill(kill) {
      const kills = this.game.kills.filter(
        (item) =>
          item.playerId == kill.playerId && item.weaponName == kill.weaponName
      );

      if (kills.length > 0) {
        const indexOfElement = this.game.kills.indexOf(kills[0]);
        this.game.kills.splice(indexOfElement, 1);
      }
      this.updateGame();
    },
    resetKills() {
      this.game.kills = [];
      this.updateGame();
    },
    resetWeapons(preset) {
      console.log(preset);
      this.game.kills = [];
      this.game.weapons = preset;
      this.updateGame();
    },
  },

  computed: {},

  watch: {
    gameId: {
      immediate: true,
      handler(id) {
        this.$bind("game", games.doc(id));
      },
    },
  },
};
</script>

<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
