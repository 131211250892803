const allWeapons = [
  {
    name: 'USP',
    icon: 'weapon_usp_silencer.svg'
  },
  {
    name: 'P250',
    icon: 'weapon_p250.svg'
  },
  {
    name: 'P2000',
    icon: 'weapon_hkp2000.svg',
  },
  {
    name: 'Five-Seven',
    icon: 'weapon_fiveseven.svg',
  },
  {
    name: 'CZ75-Auto',
    icon: 'weapon_cz75a.svg',
  },
  {
    name: 'Dual Barettas',
    icon: 'weapon_elite.svg',
  },
  {
    name: 'Desert Eagle',
    icon: 'weapon_deagle.svg',
  },
  {
    name: 'Famas',
    icon: 'weapon_famas.svg',
  },
  {
    name: 'M4A1',
    icon: 'weapon_m4a1.svg',
  },
  {
    name: 'AUG',
    icon: 'weapon_aug.svg',
  },
  {
    name: 'SCAR-20',
    icon: 'weapon_scar20.svg',
  },
  {
    name: 'SSG08',
    icon: 'weapon_ssg08.svg',
  },
  {
    name: 'AWP',
    icon: 'weapon_awp.svg',
  },
  {
    name: 'MP9',
    icon: 'weapon_mp9.svg',
  },
  {
    name: 'PP-Bizon',
    icon: 'weapon_bizon.svg',
  },
  {
    name: 'MP7',
    icon: 'weapon_mp7.svg',
  },
  {
    name: 'UMP-45',
    icon: 'weapon_ump45.svg',
  },
  {
    name: 'P90',
    icon: 'weapon_p90.svg',
  },
  {
    name: 'MAG-7',
    icon: 'weapon_mag7.svg',
  },
  {
    name: 'Nova',
    icon: 'weapon_nova.svg',
  },
  {
    name: 'XM1014',
    icon: 'weapon_xm1014.svg',
  },
  {
    name: 'Nevev',
    icon: 'weapon_negev.svg',
  },
  {
    name: 'Glock',
    icon: 'weapon_glock.svg',
  },
  {
    name: 'Tec-9',
    icon: 'weapon_tec9.svg',
  },
  {
    name: 'Galil',
    icon: 'weapon_galilar.svg',
  },
  {
    name: 'AK-47',
    icon: 'weapon_ak47.svg',
  },
  {
    name: 'SG 553',
    icon: 'weapon_sg556.svg',
  },
  {
    name: 'MAC-10',
    icon: 'weapon_mac10.svg',
  },
  {
    name: 'Sawed-Off',
    icon: 'weapon_sawedoff.svg',
  },
  {
    name: 'M249',
    icon: 'weapon_m249.svg',
  },
  {
    name: 'Zeus',
    icon: 'weapon_taser.svg',
  },
  {
    name: 'Knife',
    icon: 'weapon_knife_t.svg',
  }
];
export default allWeapons;