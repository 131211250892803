<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Add Player</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                autofocus
                label="Player name"
                v-model="playerName"
                required
                @keyup.native.enter="add"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="close">
          Close
        </v-btn>
        <v-btn color="primary" type="submit" @click="add">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddPlayer",
  components: {},
  data() {
    return {
      dialog: false,
      playerName: "",
    };
  },

  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.playerName = "";
      this.dialog = false;
    },
    add() {
      if (this.playerName.trim().length > 0) {
        this.$emit("addedPlayer", this.playerName);
        this.playerName = "";
        this.dialog = false;
      }
    },
  },
};
</script>
