<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Use Preset</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="mb-5">
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-for="(preset, i) in presets"
                    :key="i"
                    @click="usePreset(preset)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="preset.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
          <v-row>
            <h4>Or shuffle some random weapons</h4>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <v-select
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                label="# Weapons"
                v-model="numberWeapons"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="4">
              <v-select
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                v-model="numberKills"
                label="# Kills"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn color="primary" @click="useRandomPreset" class="mt-4">
                <v-icon>mdi-repeat</v-icon>Go
              </v-btn></v-col
            >
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import allWeapons from "./Weapons";
export default {
  name: "UsePreset",
  components: {},
  data() {
    return {
      dialog: false,
      numberWeapons: 3,
      numberKills: 3,
      presets: [
        {
          name: "Reset Weapons",
          weapons: [],
        },
        {
          name: "Classic",
          weapons: [
            {
              name: "P250",
              kills: 3,
            },
            {
              name: "Dual Barettas",
              kills: 3,
            },
            {
              name: "UMP-45",
              kills: 3,
            },
            {
              name: "Nova",
              kills: 3,
            },
            {
              name: "Famas",
              kills: 3,
            },
            {
              name: "Zeus",
              kills: 1,
            },
          ],
        },
      ],
    };
  },

  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    reset() {
      this.$emit("resetKills");
      this.dialog = false;
    },
    randomWeapons(amount, kills) {
      let weapons = [];

      do {
        const weapon =
          allWeapons[Math.floor(Math.random() * allWeapons.length)];
        if (weapons.indexOf(weapon) === -1) {
          weapons.push(weapon);
        }
      } while (weapons.length < amount);
      console.log(weapons);
      return weapons.map((weapon) => {
        return { name: weapon.name, kills: kills };
      });
    },
    usePreset(preset) {
      this.dialog = false;
      this.$emit("usePreset", preset.weapons);
    },

    useRandomPreset() {
      this.usePreset({
        name: "random",
        weapons: this.randomWeapons(this.numberWeapons, this.numberKills),
      });
    },
  },
};
</script>
