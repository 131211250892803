<template>
  <svg
    :class="{ active: this.active }"
    @click="handleClick"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M12,2A9,9 0 0,0 3,11C3,14.03 4.53,16.82 7,18.47V22H9V19H11V22H13V19H15V22H17V18.46C19.47,16.81 21,14 21,11A9,9 0 0,0 12,2M8,11A2,2 0 0,1 10,13A2,2 0 0,1 8,15A2,2 0 0,1 6,13A2,2 0 0,1 8,11M16,11A2,2 0 0,1 18,13A2,2 0 0,1 16,15A2,2 0 0,1 14,13A2,2 0 0,1 16,11M12,14L13.5,17H10.5L12,14Z"
    />
  </svg>
</template>
<script>
export default {
  name: "Kill",
  props: ["active", "weapon", "player"],
  methods: {
    handleClick() {
      if (this.active) {
        this.$emit("removeKill", {
          playerId: this.player.id,
          weaponName: this.weapon.name,
        });
      } else {
        this.$emit("addKill", {
          playerId: this.player.id,
          weaponName: this.weapon.name,
        });
      }
    },
  },
};
</script>

<style scoped>
svg {
  cursor: pointer;
  fill: #f1f1f1;
}

svg.active {
  fill: #000000;
}
</style>
